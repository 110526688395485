import React, { useState } from "react"
import { Grid, useMediaQuery, useTheme } from "@mui/material"
import { StaticImage } from "gatsby-plugin-image"
import Font16 from "components/Fonts/Font16"
import Button from "components/Buttons/Button"
import Carousel from "./components/Carousel/Carousel"
import ApplicationFormModal from "components/AppliccationFormModal/ApplicationFormModal"

const HomepageHeader = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))
  const [openModal, setOpenModal] = useState(false)

  const handleClick = () => {
    setOpenModal(true)
  }
  return (
    <Grid
      container
      justifyContent={"flex-end"}
      direction={isMobile ? "column-reverse" : "row"}
    >
      <Grid
        xs={12}
        md={4}
        item
        sx={{ display: "flex", width: "100%", flexDirection: "column" }}
      >
        <Grid
          container
          height={"100%"}
          direction={"column"}
          justifyContent={"center"}
        >
          <Grid
            item
            sx={{ mb: 3, ml: isMobile ? 0 : -6, mt: isMobile ? 3 : 0 }}
          >
            <StaticImage
              src="../../../images/medira-logo-big.png"
              width={305}
              quality={95}
              formats={["auto", "webp", "avif"]}
              alt="Logo Medira"
              placeholder="transparent"
            />
          </Grid>
          <Grid item>
            <Font16
              color="secondary"
              mb={5}
              sx={{ width: isMobile ? "95%" : " 70%" }}
              textAlign="justify"
            >
              Medira ist ein vertrauenswürdiges Unternehmen. Mit der
              Rekrutierung von Betreuungspersonal beschäftigen wir uns seit über
              7 Jahren.
            </Font16>
          </Grid>
          <Grid
            item
            container={isMobile}
            justifyContent={isMobile ? "center" : "flex-start"}
          >
            <Button onClick={handleClick}>Abschicken</Button>
          </Grid>
        </Grid>
        {openModal && (
          <ApplicationFormModal
            openModal={openModal}
            onClose={() => setOpenModal(false)}
          />
        )}
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        sx={{ mr: { xs: -2, sm: -3 }, ml: { xs: -2, sm: -3, lg: 0 } }}
      >
        <Carousel />
      </Grid>
    </Grid>
  )
}

export default HomepageHeader
