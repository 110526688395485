import React from "react"
import Font42 from "components/Fonts/Font42"
import Font22 from "components/Fonts/Font22"
import Font18 from "components/Fonts/Font18"
import Link from "components/Buttons/Link"
import { graphql, useStaticQuery } from "gatsby"
import { Grid, useMediaQuery } from "@mui/material"
import NewsPostCard from "./components/NewsPostCard"

const NewsSection = () => {
  const query = useStaticQuery(graphql`
    query LimitedNewsPosts {
      allContentfulPostAktualnosci(
        limit: 4
        sort: { order: DESC, fields: createdAt }
        filter: { hideSite: { eq: false } }
      ) {
        nodes {
          createdAt(formatString: "DD.MM.yyyy")
          title
          contentful_id
          shortDescription
          mainImage {
            gatsbyImageData(
              width: 250
              height: 135
              quality: 50
              backgroundColor: "transparent"
              placeholder: NONE
            )
          }
        }
      }
    }
  `)

  const posts = query.allContentfulPostAktualnosci.nodes
  const isMin1024 = useMediaQuery("(min-width:1057px)")

  return (
    <Grid mb={20}>
      <section>
        <Font42 textAlign="center" mb={5}>
          Neuigkeiten
        </Font42>
        <Grid container spacing={4} mb={4} justifyContent="center">
          {!!posts.length ? (
            posts.map(post => (
              <Grid
                item
                xs={11}
                sm={6}
                md={isMin1024 ? 3 : 4}
                key={post.contentful_id}
                container
                justifyContent="center"
              >
                <NewsPostCard {...post} />
              </Grid>
            ))
          ) : (
            <Grid item>
              <Font22 textAlign="center" mt={6} mb={6}>
                Keine Neuigkeiten
              </Font22>
            </Grid>
          )}
        </Grid>
        <Font18 textAlign="center" color="secondary">
          <Link to="/neuigkeiten" underline>
            Alle Nachrichten anzeigen
          </Link>
        </Font18>
      </section>
    </Grid>
  )
}

export default NewsSection
