import { GatsbyImage } from "gatsby-plugin-image"
import React, { useState } from "react"
import { Warpper } from "./style"
import Font32 from "components/Fonts/Font32"
import Font22 from "components/Fonts/Font22"
import Button from "components/Buttons/Button"
import { Grid, useMediaQuery, useTheme } from "@mui/material"
import ApplicationFormModal from "components/AppliccationFormModal/ApplicationFormModal"

const SliderCard = ({
  image,
  title,
  description,
  buttonText,
  link,
  buttonAction,
  ...rest
}) => {
  const [openModal, setOpenModal] = useState(false)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))
  const action =
    buttonAction === "Otwórz modal z formularzem rejestracji zgłoszenia"
      ? "OPEN_MODAL"
      : null

  const handleClick = () => {
    if (action === "OPEN_MODAL") {
      setOpenModal(true)
    }
  }
  return (
    <Grid {...rest}>
      <GatsbyImage
        image={image.gatsbyImageData}
        style={{ maxHeight: "500px", minHeight: "500px", width: "100%" }}
        alt={title}
      />
      <Warpper isMobile={isMobile}>
        <Font32 color="white" sx={{ textAlign: "center" }}>
          {title}
        </Font32>
        {description && (
          <Font22 color="white" fw={400} sx={{ textAlign: "center" }}>
            {description}
          </Font22>
        )}
        {buttonText && (
          <Button
            sx={{ mt: "20px" }}
            to={link || null}
            component={link && link.indexOf("http") > -1 ? "anchor" : null}
            onClick={handleClick}
          >
            {buttonText}
          </Button>
        )}
        {openModal && (
          <ApplicationFormModal
            openModal={openModal}
            onClose={() => setOpenModal(false)}
          />
        )}
      </Warpper>
    </Grid>
  )
}

export default SliderCard
