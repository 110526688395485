import Carousel from "react-material-ui-carousel"
import styled from "styled-components"

export const CarouselWrapper = styled(Carousel)`
  min-height: 500px;
  max-height: 500px;
  &::before {
    display: ${({ isMobile }) => (isMobile ? "none" : "block")};
    position: absolute;
    z-index: 999;
    left: 0;
    content: "";
    width: 0;
    height: 0;
    border-top: 500px solid transparent;
    border-bottom: 0px solid transparent;
    border-left: 70px solid white;
  }
`
