import styled from "styled-components"

export const Warpper = styled.div`
  background: rgba(59, 178, 196, 0.65);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 ${({ isMobile }) => (isMobile ? "20px" : "80px")};
  flex-direction: column;
`
