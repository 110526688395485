import * as React from "react"
import Layout from "hoc/Layout"
import HomepageHeader from "containers/Homepage/HompageHeader/HomepageHeader"
import { Container, useMediaQuery } from "@mui/material"
import FormSection from "containers/Homepage/FormSection/FormSection"
import RecrutationsSteps from "containers/Homepage/RecrutationsSteps/RecrutationsSteps"
import NewsSection from "containers/Homepage/NewsSection/NewsSection"
import { useTheme } from "@mui/system"

const IndexPage = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))
  return (
    <Layout seoTitle="Medira" containerSize={"xl"}>
      <HomepageHeader />
      <Container sx={{ px: isMobile ? "0 !important" : "initial" }}>
        <FormSection />
      </Container>
      <RecrutationsSteps />
      <Container>
        <NewsSection />
      </Container>
    </Layout>
  )
}

export default IndexPage
